import { Preferences } from '@capacitor/preferences'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useApi } from '~/composables/api'
import type { User } from '~/types/User'

export const useUserStore = defineStore('user', () => {
  const user = ref<User>()
  const token = ref<string | null>(null)

  const clearUser = async (): Promise<void> => {
    token.value = null
    user.value = undefined
    await Preferences.remove({ key: 'authToken' })
  }

  const logout = async (): Promise<void> => {
    try {
      const { $fetch } = useApi()
      await $fetch('/me/logout', {
        method: 'POST'
      })
    } catch (error) {
      console.error(error)
    }

    await clearUser()
  }

  const login = async ({ email, password }: { email: string, password: string }): Promise<void> => {
    const { $fetch } = useApi()
    const { token: innerToken } = await $fetch<{ token: string }>('/auth', {
      method: 'POST',
      body: JSON.stringify({
        as: 'customer',
        email,
        password
      })
    })
    token.value = innerToken
  }

  const getUser = async (): Promise<void> => {
    try {
      const { $fetch } = useApi()
      const { data } = await $fetch<{ data: User }>('/me')
      if (data) {
        user.value = data
      }
    } catch (error) {
      console.error('Error fetching user', error)
    }
  }

  const acceptTermsAndConditions = async (): Promise<void> => {
    try {
      const { $fetch } = useApi()
      if (!user.value?.email) {
        return
      }
      user.value.t_c_accepted = true
      await $fetch(`/customers/${user.value?.id}`, {
        method: 'PUT',
        body: user.value
      })
    } catch (error) {
      console.error(error)
    }
  }

  const resetPassword = async (email: string): Promise<void> => {
    const { $fetch } = useApi()
    await $fetch('/password-reset/', {
      method: 'POST',
      body: {
        as: 'customer',
        email
      }
    })
  }

  return {
    logout,
    login,
    getUser,
    acceptTermsAndConditions,
    resetPassword,
    user,
    token,
    clearUser
  }
}, {
  persist: true
})
