import type {
  NavigationFailure,
  RouteLocationNamedRaw,
  RouteLocationNormalized,
  RouteLocationPathRaw
} from 'vue-router'
import { defineNuxtRouteMiddleware, navigateTo } from '#app'
import { useUserStore } from '~/store/user'

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized): Promise<void | NavigationFailure | false | string | RouteLocationPathRaw | RouteLocationNamedRaw> => {
  if (to.name === 'login' || to.name === 'forgot' || to.name === 'forgot-success') {
    return
  }

  const userStore = useUserStore()

  if (!userStore.token) {
    return navigateTo('/login')
  }

  // If user is already fetched, return
  if (userStore.user?.id) {
    return
  }

  // Fetch user if not already fetched
  try {
    await userStore.getUser()
  } catch (error) {
    console.error('Error fetching user', error)
  }
})
