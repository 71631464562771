import type { $Fetch, FetchResponse } from 'ofetch'
import { ofetch } from 'ofetch'
import { useUserStore } from '~/store/user'
import { useNuxtApp, useRouter } from '#app'

export const useApi = (): {
  $fetch: $Fetch
  $fetchWithoutAuth: $Fetch
} => {
  const router = useRouter()
  const { $i18n: i18n } = useNuxtApp()
  const locale = i18n.locale.value || 'en'
  const config = useRuntimeConfig()
  const userStore = useUserStore()
  const baseURL = config.public.backendBaseUrl
  const { token } = userStore
  const logout = async (status: number): Promise<void> => {
    if ([401].includes(status)) {
      await userStore.clearUser()
      await router.push('/login')
    }
  }
  const onResponseError = async ({ response }: { response: FetchResponse<unknown> }): Promise<void> => {
    try {
      await logout(response.status)
    } catch (error) {
      console.error(error)
    }
  }
  const onRequestError = async ({ response }: { response?: FetchResponse<any> }): Promise<void> => {
    try {
      if (!response) {
        console.log('No internet connection?')
        return
      }
      await logout(response.status)
    } catch (error) {
      console.error(error)
    }
  }
  const $fetch: $Fetch = ofetch.create({
    baseURL,
    headers: {
      'Accept-Language': locale,
      'authorization': `Bearer ${token}`
    },
    onResponseError,
    onRequestError
  })
  const $fetchWithoutAuth: $Fetch = ofetch.create({
    baseURL,
    headers: {
      'Accept-Language': locale
    },
    onResponseError,
    onRequestError
  })
  return {
    $fetch,
    $fetchWithoutAuth
  }
}
