import { Preferences } from '@capacitor/preferences'
import { useUserStore } from '~/store/user'

export default defineNuxtPlugin(async () => {
  const { value: authToken } = await Preferences.get({ key: 'authToken' })

  const router = useRouter()

  if (!authToken) {
    await router.push('/login')
    return
  }

  const userStore = useUserStore()
  userStore.token = authToken

  await router.push('/')
})
